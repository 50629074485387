export const termsDE = `
# Nutzungsbedingungen

**Version: 1.0.0**
**Stand: 8. November 2024**

## 1. Allgemeine Bestimmungen

### 1.1 Geltungsbereich
Diese Nutzungsbedingungen regeln die Nutzung von predictsomething.com (nachfolgend "der Dienst") und stellen eine rechtsverbindliche Vereinbarung zwischen Ihnen und [Ihr Unternehmen/Name] dar.

### 1.2 Annahme
Mit dem Zugriff auf oder der Nutzung des Dienstes stimmen Sie diesen Nutzungsbedingungen zu. Wenn Sie mit Teilen dieser Bedingungen nicht einverstanden sind, dürfen Sie den Dienst nicht nutzen.

## 2. Leistungsbeschreibung

### 2.1 Zweck
Der Dienst ermöglicht Nutzern:
- Wahrscheinlichkeitsvorhersagen zu erstellen
- Vorhersageverläufe zu verfolgen
- Vorhersagen zu teilen
- Vorhersagen im Zeitverlauf zu aktualisieren

### 2.2 Keine Gewähr für Richtigkeit
Wir übernehmen keine Gewähr für die Richtigkeit der über unseren Dienst erstellten Vorhersagen. Alle Vorhersagen werden von Nutzern erstellt und spiegeln persönliche Einschätzungen wider.

## 3. Nutzerkonten

### 3.1 Kontoerstellung
- Sie müssen eine gültige E-Mail-Adresse angeben
- Sie sind für die Sicherheit Ihres Kontos verantwortlich
- Sie müssen uns über jede unbefugte Nutzung Ihres Kontos informieren

### 3.2 Voraussetzungen für ein Konto
- Sie müssen mindestens 16 Jahre alt sein
- Sie müssen korrekte Angaben machen
- Ein Konto pro Person

### 3.3 Kontokündigung
Wir behalten uns das Recht vor, Konten zu kündigen oder zu sperren bei:
- Verstoß gegen diese Nutzungsbedingungen
- Längerer Inaktivität
- Auf Wunsch des Nutzers
- Anderen berechtigten Gründen

## 4. Nutzerinhalte

### 4.1 Ihre Inhalte
Sie behalten die Rechte an Ihren erstellten Vorhersagen, gewähren uns jedoch das Recht:
- Ihre Vorhersagen zu speichern
- Ihre Vorhersagen anzuzeigen
- Ihre Vorhersagen gemäß Ihren Einstellungen zu teilen

### 4.2 Verbotene Inhalte
Sie dürfen keine Vorhersagen erstellen, die:
- Gegen Gesetze verstoßen
- Rechte Dritter verletzen
- Schädliche oder böswillige Inhalte enthalten
- Hassrede oder Diskriminierung fördern
- Spam oder irreführende Informationen enthalten

## 5. Sharing-Funktionen

### 5.1 Teilungsfunktionalität
- Sie können Vorhersagen über eindeutige URLs teilen
- Geteilte Vorhersagen sind öffentlich zugänglich
- Sie können das Teilen jederzeit deaktivieren

### 5.2 Geteilte Inhalte
- Sie sind für die von Ihnen geteilten Inhalte verantwortlich
- Wir behalten uns das Recht vor, unangemessene geteilte Inhalte zu entfernen
- Geteilte Vorhersagen können von jedem mit dem Link eingesehen werden

## 6. Verfügbarkeit des Dienstes

### 6.1 Verfügbarkeit
- Wir streben eine 24/7-Verfügbarkeit an, garantieren diese jedoch nicht
- Wir können jederzeit Wartungsarbeiten durchführen
- Der Dienst kann aus technischen Gründen unterbrochen werden

### 6.2 Aktualisierungen
Wir behalten uns das Recht vor:
- Funktionen zu ändern oder einzustellen
- Den Dienst zu aktualisieren
- Diese Nutzungsbedingungen mit Ankündigung zu ändern

## 7. Haftung

### 7.1 Haftungsausschluss
Der Dienst wird "wie besehen" ohne jegliche ausdrückliche oder stillschweigende Gewährleistung bereitgestellt.

### 7.2 Haftungsbeschränkung
Wir haften nicht für:
- Die Richtigkeit von Vorhersagen
- Nutzergenerierte Inhalte
- Dienstunterbrechungen
- Datenverlust
- Indirekte oder Folgeschäden

## 8. Datenschutz

### 8.1 Datenschutzerklärung
Die Nutzung des Dienstes unterliegt auch unserer Datenschutzerklärung, die Bestandteil dieser Nutzungsbedingungen ist.

### 8.2 Datenverarbeitung
Wir verarbeiten personenbezogene Daten wie in unserer Datenschutzerklärung beschrieben und in Übereinstimmung mit den DSGVO-Anforderungen.

## 9. Kündigung

### 9.1 Ihr Kündigungsrecht
Sie können Ihr Konto jederzeit kündigen:
- Über die Profileinstellungen
- Per E-Mail an den Support

### 9.2 Unser Kündigungsrecht
Wir können den Zugang zu unserem Dienst sofort und ohne vorherige Ankündigung beenden oder aussetzen bei:
- Verstößen gegen die Nutzungsbedingungen
- Gesetzlichen Anforderungen
- Sicherheitsgründen

## 10. Änderungen der Nutzungsbedingungen

### 10.1 Aktualisierungen
- Wir können diese Nutzungsbedingungen jederzeit ändern
- Änderungen treten mit der Veröffentlichung in Kraft
- Die weitere Nutzung gilt als Annahme der Änderungen

### 10.2 Benachrichtigung
Wir informieren Nutzer über wesentliche Änderungen über:
- E-Mail
- Dienst-Benachrichtigung
- Website-Ankündigung

## 11. Kontakt

Bei Fragen zu diesen Nutzungsbedingungen wenden Sie sich bitte an:
Joshua Gnizak
E-Mail: line1dstudio@gmail.com

## 12. Geltendes Recht

Diese Nutzungsbedingungen unterliegen deutschem Recht unter Ausschluss des UN-Kaufrechts und der Kollisionsnormen des internationalen Privatrechts.

## 13. Salvatorische Klausel

Sollten einzelne Bestimmungen dieser Nutzungsbedingungen unwirksam oder undurchführbar sein oder werden, bleibt davon die Wirksamkeit der übrigen Bestimmungen unberührt. An die Stelle der unwirksamen oder undurchführbaren Bestimmung soll diejenige wirksame und durchführbare Regelung treten, deren Wirkungen der ursprünglichen Zielsetzung am nächsten kommen.

Stand: 8. November 2024
`;