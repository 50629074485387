import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const getResolutionText = (chance, didHappen) => {
    const predictionType = 
        chance >= 70 ? 'high' :
        chance <= 30 ? 'low' :
        'neutral';

    const isCorrect = (
        (predictionType === 'high' && didHappen) ||
        (predictionType === 'low' && !didHappen) ||
        predictionType === 'neutral'
    );

    const conjunction = isCorrect ? 'and' : 'but';
    const showIcon = predictionType !== 'neutral';
    let icon = '';
    let iconColor = '';
    
    if (showIcon) {
        icon = isCorrect ? '✓' : '✕';
        iconColor = isCorrect ? '#4CAF50' : '#f44336';
    }
    
    return (
        <p style={{ 
            margin: '20px 0', 
            fontSize: '1.1em',
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
        }}>
            {showIcon && (
                <span style={{ 
                    color: iconColor,
                    fontSize: '1.2em'
                }}>
                    {icon}
                </span>
            )}
            I predicted a {chance}% chance {conjunction} it {didHappen ? 'did' : "didn't"} happen
        </p>
    );
};

const SharedPrediction = () => {
    const { shareId } = useParams();
    const navigate = useNavigate();
    const [prediction, setPrediction] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSharedPrediction = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/predictions/shared/${shareId}`);
                if (!response.ok) throw new Error('Prediction not found');
                const data = await response.json();
                setPrediction(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchSharedPrediction();
    }, [shareId]);

    if (loading) return <div className="prediction-details">Loading...</div>;

    if (error || !prediction) {
        return (
            <div className="prediction-details">
                <h2>Prediction not found</h2>
                <button onClick={() => navigate('/')} className="create-prediction-btn">
                    Create Your Own Prediction
                </button>
            </div>
        );
    }

    const userText = prediction.username 
        ? `${prediction.username} ${prediction.resolved ? 'predicted' : 'predicts'}`
        : `Someone ${prediction.resolved ? 'predicted' : 'predicts'}`;

    const currentChance = prediction.current_chance !== undefined ? prediction.current_chance : prediction.currentChance;

    return (
    <div className="prediction-details">
        <p className="small-text">
            {prediction.username ? `${prediction.username} ${prediction.resolved ? 'predicted' : 'predicts'}` : 
             `Someone ${prediction.resolved ? 'predicted' : 'predicts'}`}
        </p>
        {prediction.type === 'chance' ? (
            <>
                <h1 className="secondary-prediction-text">
                    there{prediction.resolved ? ' was' : "'s"} a {currentChance}% chance of {prediction.title}
                </h1>
                {Boolean(prediction.resolved) && getResolutionText(currentChance, prediction.resolution_value)}
            </>
        ) : (
            <>
                <h1 className="secondary-prediction-text">
                    {prediction.title}
                </h1>
                {Boolean(prediction.resolved) && (
                    <p style={{ 
                        margin: '20px 0', 
                        fontSize: '1.1em',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px'
                    }}>
                        <span style={{ 
                            color: prediction.resolution_value ? '#4CAF50' : '#f44336',
                            fontSize: '1.2em'
                        }}>
                            {prediction.resolution_value ? '✓' : '✕'}
                        </span>
                        and it {prediction.resolution_value ? 'did' : "didn't"} happen
                    </p>
                )}
            </>
        )}
        <div className="share-section">
            <button onClick={() => navigate('/')} className="create-prediction-btn">
                Create Your Own Prediction
            </button>
        </div>
    </div>
);
};

export default SharedPrediction;