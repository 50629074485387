export const termsEN = `
# Terms of Service

**Version: 1.0.0**
**Last updated: November 8, 2024**

## 1. General Terms

### 1.1 Scope
These Terms of Service govern the use of predictsomething.com (hereinafter referred to as "the Service") and constitute a legally binding agreement between you and [Your Company Name].

### 1.2 Acceptance
By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, you may not access the Service.

## 2. Service Description

### 2.1 Purpose
The Service allows users to:
- Create probability predictions
- Track prediction histories
- Share predictions
- Update predictions over time

### 2.2 No Guarantee of Accuracy
We do not guarantee the accuracy of predictions made through our Service. All predictions are user-generated and reflect personal opinions.

## 3. User Accounts

### 3.1 Account Creation
- You must provide a valid email address
- You are responsible for maintaining the security of your account
- You must notify us of any unauthorized use of your account

### 3.2 Account Requirements
- You must be at least 16 years old
- You must provide accurate information
- One account per person

### 3.3 Account Termination
We reserve the right to terminate or suspend accounts for:
- Violation of these Terms
- Extended periods of inactivity
- Upon user request
- Other reasonable causes

## 4. User Content

### 4.1 Your Content
You retain ownership of predictions you create, but grant us the right to:
- Store your predictions
- Display your predictions
- Share your predictions according to your settings

### 4.2 Prohibited Content
You may not create predictions that:
- Violate any laws
- Infringe on others' rights
- Contain harmful or malicious content
- Promote hate speech or discrimination
- Contain spam or misleading information

## 5. Sharing Features

### 5.1 Sharing Functionality
- You can share predictions via unique URLs
- Shared predictions are publicly accessible
- You can disable sharing at any time

### 5.2 Shared Content
- You are responsible for content you share
- We reserve the right to remove inappropriate shared content
- Shared predictions may be viewed by anyone with the link

## 6. Service Availability

### 6.1 Availability
- We strive for 24/7 availability but don't guarantee it
- We may perform maintenance at any time
- Service may be interrupted for technical reasons

### 6.2 Updates
We reserve the right to:
- Modify or discontinue features
- Update the Service
- Change these Terms with notice

## 7. Liability

### 7.1 Disclaimer
The Service is provided "as is" without warranties of any kind, either express or implied.

### 7.2 Limitation of Liability
We shall not be liable for:
- Accuracy of predictions
- User-generated content
- Service interruptions
- Data loss
- Indirect or consequential damages

## 8. Data Protection

### 8.1 Privacy Policy
Use of the Service is also governed by our Privacy Policy, which is incorporated into these Terms.

### 8.2 Data Processing
We process personal data as described in our Privacy Policy and in accordance with GDPR requirements.

## 9. Termination

### 9.1 Your Right to Terminate
You may terminate your account at any time through:
- Profile settings
- Email request to support

### 9.2 Our Right to Terminate
We may terminate or suspend access to our Service immediately, without prior notice, for:
- Terms violations
- Legal requirements
- Security reasons

## 10. Changes to Terms

### 10.1 Updates
- We may modify these Terms at any time
- Changes will be effective upon posting
- Continued use constitutes acceptance of changes

### 10.2 Notification
We will notify users of significant changes via:
- Email
- Service notification
- Website announcement

## 11. Contact

For questions about these Terms, contact:
Joshua Gnizak
E-Mail: line1dstudio@gmail.com

## 12. Governing Law

These Terms shall be governed by and construed in accordance with the laws of Germany, without regard to its conflict of law provisions.

## 13. Severability

If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that these Terms will otherwise remain in full force and effect.

Last updated: November 8, 2024
`;