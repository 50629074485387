import React, { useState, useRef, useEffect } from 'react';

export const ShareButton = ({ title, shareId, username, chance, type, resolved, className = '' }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [copyStatus, setCopyStatus] = useState('');
  const menuRef = useRef(null);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const shareUrl = `${window.location.origin}/share/${shareId}`;
  
  const getShareText = () => {
    const userText = username ? `${username} ` : 'Someone ';
    const timeText = resolved ? 'predicted' : 'predicts';
    
    if (type === 'chance') {
      return `${userText}${timeText} there ${resolved ? 'was' : 'is'} a ${chance}% chance of ${title}`;
    }
    return `${userText}${timeText} ${title}`;
  };

  const handleNativeShare = async () => {
    try {
      await navigator.share({ 
        title: getShareText(),
        url: shareUrl 
      });
      setMenuOpen(false);
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Error sharing:', error);
      }
    }
  };

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      setCopyStatus('Copied!');
      setTimeout(() => setCopyStatus(''), 2000);
      setMenuOpen(false);
    } catch (error) {
      console.error('Error copying:', error);
    }
  };

  return (
    <div className="share-button-container" ref={menuRef}>
      <button 
        className={`share-button ${className}`}
        onClick={() => setMenuOpen(!menuOpen)}
        aria-label="Share prediction"
      >
        <span className="share-text">Share</span>
        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5f6368">
          <path d="M680-80q-50 0-85-35t-35-85q0-6 3-28L282-392q-16 15-37 23.5t-45 8.5q-50 0-85-35t-35-85q0-50 35-85t85-35q24 0 45 8.5t37 23.5l281-164q-2-7-2.5-13.5T560-760q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-24 0-45-8.5T598-672L317-508q2 7 2.5 13.5t.5 14.5q0 8-.5 14.5T317-452l281 164q16-15 37-23.5t45-8.5q50 0 85 35t35 85q0 50-35 85t-85 35Z"/>
        </svg>
      </button>
      
      {menuOpen && (
        <div className="share-menu">
          {navigator.share && (
            <button onClick={handleNativeShare} className="share-menu-item">
              <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="#5f6368">
                <path d="M720-80q-50 0-85-35t-35-85q0-7 1-14.5t3-13.5L322-392q-17 15-38 23.5t-44 8.5q-50 0-85-35t-35-85q0-50 35-85t85-35q23 0 44 8.5t38 23.5l282-164q-2-6-3-13.5t-1-14.5q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-23 0-44-8.5T638-672L356-508q2 6 3 13.5t1 14.5q0 7-1 14.5t-3 13.5l282 164q17-15 38-23.5t44-8.5q50 0 85 35t35 85q0 50-35 85t-85 35Z"/>
              </svg>
              Share via...
            </button>
          )}
          <button onClick={handleCopyLink} className="share-menu-item">
            <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="#5f6368">
              <path d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z"/>
            </svg>
            {copyStatus || 'Copy link'}
          </button>
        </div>
      )}
    </div>
  );
};

export default ShareButton;