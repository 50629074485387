import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { resetPassword } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      setError('');
      setMessage('');
      setLoading(true);
      await resetPassword(email);
      setMessage('Check your inbox for password reset instructions');
    } catch (err) {
      console.error('Reset password error:', err);
      setError(
        err.code === 'auth/user-not-found' ? 'No account found with this email' :
        'Failed to reset password. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="prediction-details">
      <h2>Reset Password</h2>
      {error && <p className="error-message">{error}</p>}
      {message && <p className="success-message" style={{ color: 'green' }}>{message}</p>}
      
      <form onSubmit={handleSubmit} className="login-form">
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="prediction-input"
            disabled={loading}
          />
        </div>

        <div className="form-actions">
          <button 
            type="submit" 
            className="create-prediction-btn"
            disabled={loading}
          >
            {loading ? 'Sending...' : 'Reset Password'}
          </button>
          <p className="register-link">
            <button
              type="button"
              onClick={() => navigate('/login')}
              className="text-button"
              disabled={loading}
            >
              Back to Login
            </button>
          </p>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;