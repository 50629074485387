// Legal document version management
const CURRENT_VERSIONS = {
  terms: {
    en: {
      version: '1.0.0',
      lastUpdated: '2024-11-08',
      validFrom: '2024-11-08',
    },
    de: {
      version: '1.0.0',
      lastUpdated: '2024-11-08',
      validFrom: '2024-11-08',
    }
  },
  privacy: {
    en: {
      version: '1.0.0',
      lastUpdated: '2024-11-08',
      validFrom: '2024-11-08',
    },
    de: {
      version: '1.0.0',
      lastUpdated: '2024-11-08',
      validFrom: '2024-11-08',
    }
  }
};

const VERSION_HISTORY = {
  terms: {
    en: [
      {
        version: '1.0.0',
        validFrom: '2024-11-08',
        validUntil: null,
        majorChanges: [
          'Initial version of Terms of Service'
        ]
      }
    ],
    de: [
      {
        version: '1.0.0',
        validFrom: '2024-11-08',
        validUntil: null,
        majorChanges: [
          'Erste Version der Nutzungsbedingungen'
        ]
      }
    ]
  },
  privacy: {
    en: [
      {
        version: '1.0.0',
        validFrom: '2024-11-08',
        validUntil: null,
        majorChanges: [
          'Initial version of Privacy Policy'
        ]
      }
    ],
    de: [
      {
        version: '1.0.0',
        validFrom: '2024-11-08',
        validUntil: null,
        majorChanges: [
          'Erste Version der Datenschutzerklärung'
        ]
      }
    ]
  }
};

// Helper functions for version management
const getLegalDocVersion = (type, lang) => {
  return CURRENT_VERSIONS[type]?.[lang]?.version || null;
};

const isVersionCurrent = (type, lang, version) => {
  return version === CURRENT_VERSIONS[type]?.[lang]?.version;
};

const getVersionHistory = (type, lang) => {
  return VERSION_HISTORY[type]?.[lang] || [];
};

const needsReacceptance = (type, lang, userVersion) => {
  if (!userVersion) return true;
  
  const currentVersion = CURRENT_VERSIONS[type]?.[lang]?.version;
  if (!currentVersion) return false;
  
  // Extract major version numbers and compare
  const userMajor = parseInt(userVersion.split('.')[0]);
  const currentMajor = parseInt(currentVersion.split('.')[0]);
  
  // Require reacceptance only for major version changes
  return userMajor < currentMajor;
};

// Export all constants and functions
export {
  CURRENT_VERSIONS,
  VERSION_HISTORY,
  getLegalDocVersion,
  isVersionCurrent,
  getVersionHistory,
  needsReacceptance
};