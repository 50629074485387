import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ShareButton } from './ShareButton';

const UPDATE_INTERVAL = 3600; // 1 hour in milliseconds
const PredictionDetails = ({ predictions, setPredictions, updatePrediction, deletePrediction }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const prediction = predictions.find(p => String(p.id) === id);
  const [newChance, setNewChance] = useState(() => 
  prediction?.current_chance ?? prediction?.currentChance ?? 50  // Change default to 50
);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [showAllHistory, setShowAllHistory] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
  if (prediction) {
    setNewChance(prediction.current_chance ?? prediction.currentChance ?? 50); // Change default to 50
  }
}, [prediction]);

  useEffect(() => {
    if (prediction?.type === 'chance' && prediction?.history?.length && !prediction.resolved) {
      const lastUpdate = prediction.history[prediction.history.length - 1]?.date || new Date();
      const lastUpdateDate = new Date(lastUpdate);
      const updateInterval = setInterval(() => {
        const now = new Date();
        const diffMs = now - lastUpdateDate;
        const waitMs = Math.max(UPDATE_INTERVAL - diffMs, 0);
        setCountdown(Math.ceil(waitMs / 60000));
      }, 1000);

      return () => clearInterval(updateInterval);
    }
  }, [prediction]);

  const handleChanceKeyDown = (e) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
      const direction = e.key === 'ArrowUp' ? 1 : -1;
      const nextValue = Math.min(100, Math.max(0, Math.round(newChance / 10) * 10 + (direction * 10)));
      setNewChance(nextValue);
    }
  };

  const handleChanceChange = (e) => {
    const rawValue = e.target.value;
    if (rawValue === '') {
      setNewChance(0);
      return;
    }

    const value = Number(rawValue);
    if (!isNaN(value) && value >= 0 && value <= 100) {
      setNewChance(value);
    }
  };
const getResolutionText = (chance, didHappen) => {
  const predictionType = 
    chance >= 70 ? 'high' :
    chance <= 30 ? 'low' :
    'neutral';

  const isCorrect = (
    (predictionType === 'high' && didHappen) ||
    (predictionType === 'low' && !didHappen) ||
    predictionType === 'neutral'
  );

  const conjunction = isCorrect ? 'and' : 'but';
  const showIcon = predictionType !== 'neutral';
  let icon = '';
  let iconColor = '';
  
  if (showIcon) {
    icon = isCorrect ? '✓' : '✕';
    iconColor = isCorrect ? '#4CAF50' : '#f44336';
  }
  
  return (
    <p style={{ 
      margin: '20px 0', 
      fontSize: '1.1em',
      display: 'flex',
      alignItems: 'center',
      gap: '8px'
    }}>
      {showIcon && (
        <span style={{ 
          color: iconColor,
          fontSize: '1.2em'
        }}>
          {icon}
        </span>
      )}
      I predicted a {chance}% chance {conjunction} it {didHappen ? 'did' : "didn't"} happen
    </p>
  );
};
  const handlePrivacyToggle = async () => {
    try {
      const updatedPrediction = {
        ...prediction,
        is_public: !prediction.is_public
      };
      
      const result = await updatePrediction(updatedPrediction);
      
      setPredictions(prev => 
        prev.map(p => p.id === prediction.id ? {
          ...result,
          is_public: result.is_public
        } : p)
      );
    } catch (error) {
      console.error('Error updating prediction privacy:', error);
    }
  };

  const handleUpdate = async () => {
    try {
      const updatedPrediction = {
        ...prediction,
        currentChance: newChance,
        current_chance: newChance,
        history: [...(prediction.history || []), { chance: newChance, date: new Date() }]
      };
      
      const result = await updatePrediction(updatedPrediction);
      
      setPredictions(prev => 
        prev.map(p => p.id === prediction.id ? {
          ...result,
          currentChance: result.current_chance,
          current_chance: result.current_chance
        } : p)
      );
      
      setIsUpdateOpen(false);
    } catch (error) {
      console.error('Error updating prediction:', error);
    }
  };

  const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }).format(new Date(date));
  };

  const handleDelete = () => {
    const confirmed = window.confirm('Are you sure you want to delete this prediction? This action cannot be undone.');
    if (confirmed) {
      deletePrediction(prediction.id);
      navigate('/');
    }
  };

  const handleShare = () => {
    const shareUrl = `${window.location.origin}/share/${prediction.shareId || prediction.share_id}`;
    navigator.clipboard.writeText(shareUrl);
    alert('Share link copied to clipboard!');
  };

  const handleResolve = async (outcome) => {
    if (loading) return;
    
    const confirmed = window.confirm(
      `Are you sure ${outcome ? 'this came true' : 'this did not come true'}? `
    );
    
    if (!confirmed) return;
    
    try {
      setLoading(true);
      const updatedPrediction = {
        ...prediction,
        resolved: true,
        resolution_value: outcome,
        resolved_at: new Date().toISOString()
      };
      
      const result = await updatePrediction(updatedPrediction);
      setPredictions(prev => 
        prev.map(p => p.id === prediction.id ? {
          ...result,
          currentChance: result.current_chance,
          resolution_value: result.resolution_value === 1
        } : p)
      );
    } catch (error) {
      console.error('Error resolving prediction:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!prediction) {
    return (
      <div className="prediction-details">
        <h2>Prediction not found</h2>
        <p>This prediction may have been deleted or the link might be incorrect.</p>
        <button onClick={() => navigate('/')} className="back-button">
          Back to Home
        </button>
      </div>
    );
  }

  const displayedHistory = showAllHistory 
    ? (prediction.history || [])
    : (prediction.history || []).slice(-9);

  const hasMoreHistory = (prediction.history || []).length > 9;
  const currentChance = prediction.current_chance || prediction.currentChance;
  const canUpdate = countdown === 0;

  return (
        <div className="prediction-details">
      <div className="header-buttons">
        <button onClick={() => navigate('/')} className="icon-button">
          <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 20 20" width="20px" fill="#5F6368">
            <path d="M2.9,10.8l4.2,4.2L6,16l-6-6l6-6l1.1,1.1L2.9,9.2H12v1.5H2.9z"/>
          </svg>
          <span className="back-text">Back</span>
        </button>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', alignItems: 'flex-end' }}>

          <ShareButton 
  title={prediction.title}
  shareId={prediction.shareId || prediction.share_id}
  username={prediction.username}
  chance={prediction.current_chance || prediction.currentChance}
  type={prediction.type}
  resolved={prediction.resolved}
  className="icon-button"
/>
        </div>
      </div>

      <p className="small-text">{prediction.resolved ? 'I predicted' : 'I predict'}</p>

      {prediction.type === 'chance' ? (
        <>
          <h1 className="secondary-prediction-text">
            there{prediction.resolved ? ' was' : "'s"} a {currentChance}% chance of {prediction.title}
          </h1>
          {Boolean(prediction.resolved) && getResolutionText(currentChance, prediction.resolution_value)}
        </>
      ) : (
        <>
          <h1 className="secondary-prediction-text">
            {prediction.title}
          </h1>
          {Boolean(prediction.resolved) && (
            <p style={{ 
              margin: '20px 0', 
              fontSize: '1.1em',
              display: 'flex',
              alignItems: 'center',
              gap: '8px'
            }}>
              <span style={{ 
                color: prediction.resolution_value ? '#4CAF50' : '#f44336',
                fontSize: '1.2em'
              }}>
                {prediction.resolution_value ? '✓' : '✕'}
              </span>
              and it {prediction.resolution_value ? 'did' : "didn't"} happen
            </p>
          )}
        </>
      )}

      <div className="prediction-history">
        {!prediction.resolved && (
          <button 
            onClick={() => setIsUpdateOpen(!isUpdateOpen)} 
            className="text-button"
          >
            {isUpdateOpen ? 'Done' : 'Update prediction'}
          </button>
        )}

        {isUpdateOpen && !prediction.resolved && (
          <div className="update-section">
            <p className="small-text">Did "{prediction.title}" come true?</p>
            <div className="chance-group" style={{ justifyContent: 'flex-start', marginBottom: '40px' }}>
              <button
                onClick={() => handleResolve(true)}
                className="update-prediction-btn"
                style={{ backgroundColor: '#2b2828', marginRight: '10px' }}
                disabled={loading}
              >
                Yes
              </button>
              <button
                onClick={() => handleResolve(false)}
                className="update-prediction-btn"
                style={{ backgroundColor: '#2b2828' }}
                disabled={loading}
              >
                No
              </button>
            </div>

            {prediction.type === 'chance' && (
              <>
                {canUpdate && (
                  <>
                    <p className="small-text">Right now I think the chance is</p>
                    <div className="chance-group">
                      <input
                        type="number"
                        className="chance-input"
                        value={newChance}
                        onChange={handleChanceChange}
                        onFocus={(e) => e.target.select()}
                        onKeyDown={handleChanceKeyDown}
                        min="0"
                        max="100"
                      />
                      <span>%</span>
                      <div className="update-buttons">
                        <button 
                          onClick={handleUpdate} 
                          className="update-prediction-btn"
                          disabled={loading}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </>
                )}

                {!canUpdate && (
                  <p className="small-text">
                    You can update again in {countdown} {countdown === 1 ? 'minute' : 'minutes'}
                  </p>
                )}

<h4>Prediction History:</h4>
                <ul className="history-list">
                  {[...displayedHistory].reverse().map((entry, index) => (
                    <li key={index}>
                      <strong>{entry.chance}%</strong> on {formatDate(entry.date)}
                    </li>
                  ))}
                </ul>
                
                {hasMoreHistory && (
                  <button 
                    onClick={() => setShowAllHistory(!showAllHistory)} 
                    className="show-all-btn"
                  >
                    {showAllHistory ? 'Show less' : `Show all (${prediction.history.length})`}
                  </button>
                )}
              </>
            )}
          </div>
        )}
        <div className="prediction-footer">
          <button 
            onClick={() => handlePrivacyToggle()} 
            className="icon-button"
            style={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: '4px',
              opacity: prediction.is_public ? 1 : 0.7 
            }}
          >
            <span className="share-text">
              {prediction.is_public ? 'Public' : 'Private'}
            </span>
            {prediction.is_public ? (
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5F6368">
                <path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm-40-82v-78q-33 0-56.5-23.5T360-320v-40L168-552q-3 18-5.5 36t-2.5 36q0 121 79.5 212T440-162Zm276-102q20-22 36-47.5t26.5-53q10.5-27.5 16-56.5t5.5-59q0-98-54.5-179T600-776v16q0 33-23.5 56.5T520-680h-80v80q0 17-11.5 28.5T400-560h-80v80h240q17 0 28.5 11.5T600-440v120h40q26 0 47 15.5t29 40.5Z"/>
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5F6368">
                <path d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm0-80h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z"/>
              </svg>
            )}
          </button>
        <button 
          onClick={handleDelete} 
          className="delete-prediction-btn"
          disabled={loading}
        >
          Delete Prediction
        </button>
        </div>
      </div>
    </div>
  );
};

export default PredictionDetails;