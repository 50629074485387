import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { 
  getAuth, 
  updatePassword, 
  EmailAuthProvider, 
  reauthenticateWithCredential 
} from 'firebase/auth';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Profile = () => {
  const navigate = useNavigate();
  const { currentUser, logout } = useAuth();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordSuccess, setPasswordSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    
    if (newPassword.length < 6) {
      setPasswordError('Password must be at least 6 characters long');
      return;
    }

    try {
      const auth = getAuth();
      // Create credential with current password
      const credential = EmailAuthProvider.credential(
        currentUser.email,
        currentPassword
      );
      
      // Re-authenticate
      await reauthenticateWithCredential(currentUser, credential);
      
      // Now change password
      await updatePassword(auth.currentUser, newPassword);
      setPasswordSuccess('Password updated successfully');
      setPasswordError('');
      setNewPassword('');
      setCurrentPassword('');
      setIsChangingPassword(false);
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        setPasswordError('Current password is incorrect');
      } else {
        setPasswordError('Failed to update password: ' + error.message);
      }
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleDeleteRequest = () => {
    setIsConfirmOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      setLoading(true);
      setDeleteError(null);
      
      const idToken = await currentUser.getIdToken(true);
      
      const response = await fetch(`${API_BASE_URL}/auth/profile`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to delete profile');
      }

      await currentUser.delete();
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Error deleting profile:', error);
      setDeleteError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelDelete = () => {
    setIsConfirmOpen(false);
    setDeleteError(null);
  };

  if (!currentUser) {
    return <div>Loading...</div>;
  }

  return (
    <div className="profile-page">
      <div className="header-buttons">
        <button onClick={() => navigate('/')} className="icon-button">
          <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 20 20" width="20px" fill="#5F6368">
            <path d="M2.9,10.8l4.2,4.2L6,16l-6-6l6-6l1.1,1.1L2.9,9.2H12v1.5H2.9z"/>
          </svg>
          <span className="back-text">Back</span>
        </button>
      </div>

      <div className="profile-content">
        <div className="profile-section">
          <p className="small-text">Email</p>
          <p className="profile-value">{currentUser.email}</p>
        </div>

        <div className="profile-section">
          {passwordSuccess && (
            <p className="success-message" style={{ marginBottom: '16px' }}>
              {passwordSuccess}
            </p>
          )}
          
          {!isChangingPassword ? (
            <button 
              onClick={() => setIsChangingPassword(true)}
              className="create-prediction-btn"
            >
              Change Password
            </button>
          ) : (
            <form onSubmit={handlePasswordChange} className="password-form">
              <input
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                placeholder="Enter current password"
                className="prediction-input"
              />
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Enter new password"
                className="prediction-input"
              />
              <div className="password-buttons">
                <button 
                  type="submit" 
                  className="create-prediction-btn"
                >
                  Update Password
                </button>
                <button 
                  type="button"
                  onClick={() => {
                    setIsChangingPassword(false);
                    setNewPassword('');
                    setCurrentPassword('');
                    setPasswordError('');
                  }}
                  className="cancel-update-btn"
                >
                  Cancel
                </button>
              </div>
              {passwordError && <p className="error-message">{passwordError}</p>}
            </form>
          )}
        </div>

        <div className="profile-actions">
          <button 
            onClick={handleLogout}
            className="create-prediction-btn"
          >
            Logout
          </button>

          <button 
            onClick={handleDeleteRequest}
            className="delete-account-btn"
          >
            Delete Account
          </button>
        </div>

        {isConfirmOpen && (
          <div className="delete-confirmation">
            <h3>Delete Account?</h3>
            <p>This will permanently delete:</p>
            <ul>
              <li>Your account</li>
              <li>All your predictions</li>
              <li>All prediction history</li>
            </ul>
            <p>This action cannot be undone.</p>
            {deleteError && <p className="error-message">{deleteError}</p>}
            <div className="confirmation-buttons">
              <button 
                onClick={handleConfirmDelete} 
                className="create-prediction-btn"
                style={{ backgroundColor: '#dc3545' }}
                disabled={loading}
              >
                {loading ? 'Deleting...' : 'Yes, Delete Everything'}
              </button>
              <button 
                onClick={handleCancelDelete}
                className="cancel-update-btn"
                disabled={loading}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;