import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <nav className="footer-nav">
        <Link to="/legal/impressum">Impressum</Link>
        <Link to="/legal/privacy/de">Datenschutz</Link>
        <Link to="/legal/terms/de">Nutzungsbedingungen</Link>
      </nav>
    </footer>
  );
};

export default Footer;