import React, { useState } from 'react';

const PredictionForm = ({ addPrediction }) => {
  const [title, setTitle] = useState('');
  const [chance, setChance] = useState(50);
  const [predictionType, setPredictionType] = useState('simple');

  const predictionTypes = [
    { id: 'simple', label: 'I predict', format: 'I predict' },
    { id: 'chance', label: 'I predict the chances of', format: 'I predict the chances of' }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const newPrediction = {
      id: Date.now(),
      title,
      type: predictionType,
      initialChance: predictionType === 'chance' ? chance : null,
      currentChance: predictionType === 'chance' ? chance : null,
      history: predictionType === 'chance' ? [{ date: new Date(), chance }] : [],
      resolved: false
    };

    addPrediction(newPrediction);
    setTitle('');
    setChance(50);
  };

  return (
    <form onSubmit={handleSubmit} className="prediction-form">
      <div className="prediction-header">
        <select 
          value={predictionType}
          onChange={(e) => setPredictionType(e.target.value)}
          className="prediction-type-select"
        >
          {predictionTypes.map(type => (
            <option key={type.id} value={type.id}>
              {type.label}
            </option>
          ))}
        </select>
      </div>

      <input
        type="text"
        className="prediction-input"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="something"
        required
      />

      {predictionType === 'chance' && (
        <div className="chance-group">
          <span>is</span>
          <input
            type="number"
            className="chance-input"
            value={chance}
            onChange={(e) => {
              const value = Number(e.target.value);
              if (!isNaN(value) && value >= 0 && value <= 100) {
                setChance(value);
              }
            }}
            onFocus={(e) => e.target.select()}
            min="0"
            max="100"
          />
          <span>%</span>
        </div>
      )}

      <button type="submit" className="create-prediction-btn">
        Create Prediction
      </button>
    </form>
  );
};

export default PredictionForm;