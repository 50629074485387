import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  sendEmailVerification, 
  sendPasswordResetEmail 
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Custom email actions
const actionCodeSettings = {
  url: process.env.REACT_APP_URL, // Your app's URL
  handleCodeInApp: true,
  // You can also add:
  iOS: {
    bundleId: 'com.example.ios'
  },
  android: {
    packageName: 'com.example.android',
    installApp: true,
    minimumVersion: '12'
  },
  dynamicLinkDomain: process.env.REACT_APP_DYNAMIC_LINK_DOMAIN
};

// Custom email verification
export const sendCustomEmailVerification = (user) => {
  return sendEmailVerification(user, actionCodeSettings);
};

// Custom password reset
export const sendCustomPasswordReset = (email) => {
  return sendPasswordResetEmail(auth, email, actionCodeSettings);
};

export { auth };
export default app;