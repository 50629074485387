export const privacyEN = `
# Privacy Policy

**Version: 1.0.0**
**Last updated: November 8, 2024**


## Preamble
With this privacy policy, we would like to inform you about what types of your personal data (hereinafter referred to as "data") we process, for what purposes, and to what extent when providing our application predictsomething.com.

## Table of Contents
1. Controller Information
2. Processing Overview
3. Firebase Authentication and User Accounts
4. Prediction Data and User Content
5. Sharing of Predictions
6. Legal Basis
7. Security Measures
8. Cookies and Local Storage
9. International Data Transfers
10. Your Rights
11. Data Storage and Deletion

## 1. Controller Information
Joshua Gnizak
Tannenhof 68D
22397 Hamburg
Germany

Email: line1dstudio@gmail.com

## 2. Processing Overview

### Types of data processed:
- Account data (email address)
- Usage data (predictions, probabilities, timestamps)
- Meta, communication, and procedural data (e.g., IP addresses, time data)
- Content data (prediction texts and histories)

### Categories of data subjects:
- Users of predictsomething.com platform
- Visitors of shared predictions

### Processing purposes:
- Provision of the prediction platform
- Authentication and account management
- Storage and management of predictions
- Enabling sharing functionality
- Ensuring security

## 3. Firebase Authentication and User Accounts

We use Firebase Authentication (a Google LLC service) for secure user management:

### Processed data:
- Email address for authentication
- Login timestamps
- Technical identifiers

### Purpose:
- Secure user login
- Account management
- Protection against unauthorized access

### Storage:
- Until account deletion
- Login data with Google Firebase (under EU standard contractual clauses)

## 4. Prediction Data and User Content

### Processed data:
- Prediction titles
- Probability assessments
- Temporal development of assessments
- Creation and update timestamps
- Share IDs for shared predictions

### Storage:
- On servers in the EU
- Deletion only through explicit user action or account deletion
- Regular backups for data security

## 5. Sharing of Predictions

### Functionality:
- Generation of unique share IDs
- Public accessibility via specific URLs
- No transmission of personal data
- Deactivation option available at any time

### Shared data:
- Prediction title
- Probabilities
- History
- Timestamps

## 6. Legal Basis

Processing is based on the following legal grounds:

- Consent (Art. 6(1)(a) GDPR)
- Contract performance and pre-contractual measures (Art. 6(1)(b) GDPR)
- Legitimate interests (Art. 6(1)(f) GDPR)

## 7. Security Measures

To protect your data, we implement the following measures:

- SSL/TLS encryption for all data transfers
- Secure authentication via Firebase
- Regular security updates
- Access restrictions for administrators
- Regular backups
- Hosting in Germany

## 8. Cookies and Local Storage

We use only technically necessary cookies:

### Technically necessary cookies:
- Firebase Authentication (login status)
- Session management
- Security functions

### We do not use:
- Marketing cookies
- Analytics cookies
- Tracking cookies

## 9. International Data Transfers

Data transfers to third countries only occur for:

- Firebase Authentication (Google LLC, USA)
  - Under EU standard contractual clauses
  - Within the framework of the EU-US Data Privacy Framework
- All other data remains in the EU

## 10. Your Rights

You have the following rights:

- Right to information (Art. 15 GDPR)
- Right to rectification (Art. 16 GDPR)
- Right to erasure (Art. 17 GDPR)
- Right to restriction of processing (Art. 18 GDPR)
- Right to data portability (Art. 20 GDPR)
- Right to object (Art. 21 GDPR)
- Right to withdraw consent (Art. 7(3) GDPR)
- Right to lodge a complaint with a supervisory authority (Art. 77 GDPR)

You can exercise these rights at any time by:
- Email to: [Your email]
- Using the account deletion function in the profile settings
- Request via the contact form

## 11. Data Storage and Deletion

### Active accounts:
- Account data remains stored as long as the account exists
- Prediction data remains until manual deletion or account deletion
- Shared predictions remain available until deactivation or account deletion

### Upon account deletion:
- Immediate deletion of all personal data
- Deletion of all predictions and their histories
- Deactivation of all shared predictions

### Log data:
- Storage of server logs for maximum 30 days
- Anonymization of technical logs after 30 days

## Changes to the Privacy Policy

We reserve the right to modify this privacy policy to ensure it complies with current legal requirements or to implement changes to our services. Your next visit will be subject to the new privacy policy.

Last updated: November 8, 2024
`;