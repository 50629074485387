export const privacyDE = `
# Datenschutzerklärung

**Version: 1.0.0**
**Stand: 8. November 2024**

## Präambel
Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend auch kurz als "Daten" bezeichnet) wir zu welchen Zwecken und in welchem Umfang im Rahmen der Bereitstellung unserer Applikation predictsomething.com verarbeiten.

Die verwendeten Begriffe sind nicht geschlechtsspezifisch.

## Inhaltsübersicht
1. Verantwortlicher
2. Übersicht der Verarbeitungen
3. Firebase Authentication und Nutzerkonten
4. Vorhersagedaten und Nutzerinhalte
5. Teilen von Vorhersagen
6. Maßgebliche Rechtsgrundlagen
7. Sicherheitsmaßnahmen
8. Cookies und lokale Speicherung
9. Internationale Datentransfers
10. Ihre Rechte
11. Datenspeicherung und Löschung

## 1. Verantwortlicher
Joshua Gnizak
Tannenhof 68D
22397 Hamburg
Deutschland

E-Mail: line1dstudio@gmail.com

## 2. Übersicht der Verarbeitungen

### Arten der verarbeiteten Daten:
- Bestandsdaten (E-Mail-Adresse)
- Nutzungsdaten (Vorhersagen, Wahrscheinlichkeiten, Zeitstempel)
- Meta-, Kommunikations- und Verfahrensdaten (z.B. IP-Adressen, Zeitangaben)
- Inhaltsdaten (Vorhersagetexte und -historien)

### Kategorien betroffener Personen:
- Nutzer der predictsomething.com Plattform
- Besucher von geteilten Vorhersagen

### Zwecke der Verarbeitung:
- Bereitstellung der Vorhersage-Plattform
- Authentifizierung und Kontoverwaltung
- Speicherung und Verwaltung von Vorhersagen
- Ermöglichung der Sharing-Funktionalität
- Gewährleistung der Sicherheit

## 3. Firebase Authentication und Nutzerkonten

Wir nutzen Firebase Authentication (ein Dienst von Google LLC) für die sichere Benutzerverwaltung:

### Verarbeitete Daten:
- E-Mail-Adresse für die Authentifizierung
- Zeitstempel der Anmeldungen
- Technische Identifikatoren

### Zweck:
- Sichere Benutzeranmeldung
- Kontoverwaltung
- Schutz vor unbefugtem Zugriff

### Speicherung:
- Bis zur Löschung des Benutzerkontos
- Anmeldedaten bei Google Firebase (unter EU-Standardvertragsklauseln)

## 4. Vorhersagedaten und Nutzerinhalte

### Verarbeitete Daten:
- Vorhersagetitel
- Wahrscheinlichkeitseinschätzungen
- Zeitliche Entwicklung der Einschätzungen
- Zeitstempel der Erstellung und Aktualisierungen
- Share-IDs für geteilte Vorhersagen

### Speicherung:
- Auf Servern in der EU
- Löschung nur durch explizite Nutzeraktion oder Kontolöschung
- Regelmäßige Backups zur Datensicherung

## 5. Teilen von Vorhersagen

### Funktionsweise:
- Generierung einer eindeutigen Share-ID
- Öffentliche Zugänglichkeit über spezifische URLs
- Keine Übermittlung personenbezogener Daten
- Deaktivierungsmöglichkeit jederzeit verfügbar

### Geteilte Daten:
- Vorhersagetitel
- Wahrscheinlichkeiten
- Verlaufshistorie
- Zeitstempel

## 6. Maßgebliche Rechtsgrundlagen

Die Verarbeitung erfolgt auf Grundlage folgender Rechtsgrundlagen:

- Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)
- Vertragserfüllung und vorvertragliche Maßnahmen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO)
- Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)

## 7. Sicherheitsmaßnahmen

Zum Schutz Ihrer Daten setzen wir folgende Maßnahmen ein:

- SSL/TLS-Verschlüsselung für alle Datenübertragungen
- Sichere Authentifizierung über Firebase
- Regelmäßige Sicherheitsupdates
- Zugriffsbeschränkungen für Administratoren
- Regelmäßige Backups
- Hosting in Deutschland

## 8. Cookies und lokale Speicherung

Wir verwenden ausschließlich technisch notwendige Cookies:

### Technisch notwendige Cookies:
- Firebase Authentication (Anmeldestatus)
- Session-Management
- Sicherheitsfunktionen

### Keine Verwendung von:
- Marketing-Cookies
- Analyse-Cookies
- Tracking-Cookies

## 9. Internationale Datentransfers

Datenübermittlungen in Drittländer finden nur statt bei:

- Firebase Authentication (Google LLC, USA)
  - Unter EU-Standardvertragsklauseln
  - Im Rahmen des EU-US Data Privacy Framework
- Alle anderen Daten verbleiben in der EU

## 10. Ihre Rechte

Sie haben folgende Rechte:

- Recht auf Auskunft (Art. 15 DSGVO)
- Recht auf Berichtigung (Art. 16 DSGVO)
- Recht auf Löschung (Art. 17 DSGVO)
- Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO)
- Recht auf Datenübertragbarkeit (Art. 20 DSGVO)
- Widerspruchsrecht (Art. 21 DSGVO)
- Widerrufsrecht bei Einwilligungen (Art. 7 Abs. 3 DSGVO)
- Beschwerderecht bei der Aufsichtsbehörde (Art. 77 DSGVO)

Diese Rechte können Sie jederzeit ausüben durch:
- E-Mail an: [Ihre E-Mail]
- Nutzung der Kontolöschfunktion in den Profileinstellungen
- Anfrage über das Kontaktformular

## 11. Datenspeicherung und Löschung

### Aktive Konten:
- Kontodaten bleiben gespeichert, solange das Konto besteht
- Vorhersagedaten bleiben erhalten bis zur manuellen Löschung oder Kontolöschung
- Geteilte Vorhersagen bleiben verfügbar bis zur Deaktivierung oder Kontolöschung

### Bei Kontolöschung:
- Sofortige Löschung aller personenbezogenen Daten
- Löschung aller Vorhersagen und deren Historien
- Deaktivierung aller geteilten Vorhersagen

### Protokolldaten:
- Speicherung von Server-Logs für maximal 30 Tage
- Anonymisierung von technischen Protokollen nach 30 Tagen

## Änderungen der Datenschutzerklärung

Wir behalten uns vor, diese Datenschutzerklärung anzupassen, um sie stets den aktuellen rechtlichen Anforderungen anzupassen oder Änderungen unserer Leistungen umzusetzen. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.

Stand: 8. November 2024
`;