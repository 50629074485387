// src/components/LegalPage.js
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { privacyDE } from '../legal-documents/privacy-de';
import { privacyEN } from '../legal-documents/privacy-en';
import { termsDE } from '../legal-documents/terms-de';
import { termsEN } from '../legal-documents/terms-en';
import { impressumContent } from '../legal-documents/impressum';
import { CURRENT_VERSIONS, getVersionHistory } from '../legal-documents/versions';

const LegalPage = () => {
  const navigate = useNavigate();
  const { type, lang } = useParams();

  const getCurrentVersion = () => {
    return CURRENT_VERSIONS[type]?.[lang];
  };

  const getContent = () => {
    switch (type) {
      case 'privacy':
        return lang === 'en' ? privacyEN : privacyDE;
      case 'terms':
        return lang === 'en' ? termsEN : termsDE;
      case 'impressum':
        return impressumContent;
      default:
        return '# Page Not Found';
    }
  };

  const renderVersionInfo = () => {
    const version = getCurrentVersion();
    if (!version || type === 'impressum') return null;

    return (
      <div className="version-info">
        <button 
          onClick={() => document.getElementById('version-history').scrollIntoView({ behavior: 'smooth' })}
          className="text-button"
        >
          View Version History
        </button>
      </div>
    );
  };

  const renderVersionHistory = () => {
    if (type === 'impressum') return null;
    
    return (
      <div id="version-history" className="version-history">
        <h2>{lang === 'en' ? 'Version History' : 'Versionshistorie'}</h2>
        {getVersionHistory(type, lang).map((hist, index) => (
          <div key={index} className="version-entry">
            <h3>Version {hist.version}</h3>
            <p>
              {lang === 'en' ? 'Valid from: ' : 'Gültig ab: '}
              {hist.validFrom}
            </p>
            {hist.validUntil && (
              <p>
                {lang === 'en' ? 'Valid until: ' : 'Gültig bis: '}
                {hist.validUntil}
              </p>
            )}
            <h4>{lang === 'en' ? 'Major Changes:' : 'Wichtige Änderungen:'}</h4>
            <ul>
              {hist.majorChanges.map((change, i) => (
                <li key={i}>{change}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  };

  // Enhanced format content function
  const formatContent = (content) => {
    let inList = false;
    let listItems = [];

    return content.split('\n').map((line, index) => {
      // Handle headings
      if (line.startsWith('# ')) {
        return <h1 key={index}>{line.substring(2)}</h1>;
      }
      if (line.startsWith('## ')) {
        return <h2 key={index}>{line.substring(3)}</h2>;
      }
      if (line.startsWith('### ')) {
        return <h3 key={index}>{line.substring(4)}</h3>;
      }

      // Handle lists
      if (line.startsWith('- ') || line.startsWith('* ')) {
        if (!inList) {
          inList = true;
          listItems = [];
        }
        listItems.push(line.substring(2));
        
        // If next line isn't a list item, render the list
        const nextLine = content.split('\n')[index + 1];
        if (!nextLine || (!nextLine.startsWith('- ') && !nextLine.startsWith('* '))) {
          inList = false;
          return (
            <ul key={index}>
              {listItems.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          );
        }
        return null;
      }

      // Handle empty lines
      if (line.trim() === '') {
        return <br key={index} />;
      }

      // Handle regular paragraphs
      if (line.trim().length > 0) {
        // Basic email link detection
        const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/g;
        const parts = line.split(emailRegex);
        
        if (parts.length > 1) {
          return (
            <p key={index}>
              {parts.map((part, i) => {
                if (part.match(emailRegex)) {
                  return <a key={i} href={`mailto:${part}`}>{part}</a>;
                }
                return part;
              })}
            </p>
          );
        }
        return <p key={index}>{line}</p>;
      }

      return null;
    }).filter(Boolean); // Remove null elements
  };

  return (
    <div className="prediction-details">
      <div className="header-buttons">
        <button onClick={() => navigate('/')} className="icon-button">
          <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 20 20" width="20px" fill="#5F6368">
            <path d="M2.9,10.8l4.2,4.2L6,16l-6-6l6-6l1.1,1.1L2.9,9.2H12v1.5H2.9z"/>
          </svg>
          <span className="back-text">Back</span>
        </button>
        {(type === 'privacy' || type === 'terms') && (
          <button 
            onClick={() => navigate(`/legal/${type}/${lang === 'en' ? 'de' : 'en'}`)} 
            className="icon-button"
          >
            {lang === 'en' ? '🇩🇪 Deutsch' : '🇬🇧 English'}
          </button>
        )}
      </div>

      <div className="legal-content">
        {renderVersionInfo()}
        <div>{formatContent(getContent())}</div>
        {renderVersionHistory()}
      </div>
    </div>
  );
};

export default LegalPage;