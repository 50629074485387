import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { sendEmailVerification } from 'firebase/auth';

const EmailVerification = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [timeUntilResend, setTimeUntilResend] = useState(0);

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
    } else if (currentUser.emailVerified) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    if (timeUntilResend > 0) {
      const timer = setTimeout(() => {
        setTimeUntilResend(time => time - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [timeUntilResend]);

  const handleResendVerification = async () => {
    try {
      setLoading(true);
      setError('');
      await sendEmailVerification(currentUser);
      setMessage('Verification email sent! Check your inbox.');
      setTimeUntilResend(60); // Set 60-second cooldown
    } catch (err) {
      setError('Failed to resend verification email. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (err) {
      setError('Failed to log out');
    }
  };

  const handleRefresh = async () => {
    try {
      setLoading(true);
      await currentUser.reload();
      if (currentUser.emailVerified) {
        navigate('/');
      } else {
        setMessage('Email not verified yet. Please check your inbox.');
      }
    } catch (err) {
      setError('Failed to check verification status');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="prediction-details">
      <h2>Verify Your Email</h2>
      <div className="profile-content">
        <p>We've sent a verification email to:</p>
        <p className="profile-value">{currentUser?.email}</p>
        
        {error && <p className="error-message">{error}</p>}
        {message && <p style={{ color: 'green' }}>{message}</p>}

        <div className="profile-actions" style={{ gap: '12px' }}>
          <button
            onClick={handleRefresh}
            className="create-prediction-btn"
            disabled={loading}
          >
            I've Verified My Email
          </button>

          <button
            onClick={handleResendVerification}
            className="create-prediction-btn"
            disabled={loading || timeUntilResend > 0}
            style={{ opacity: timeUntilResend > 0 ? 0.7 : 1 }}
          >
            {timeUntilResend > 0 
              ? `Resend in ${timeUntilResend}s` 
              : 'Resend Verification Email'}
          </button>

          <button onClick={handleLogout} className="cancel-update-btn">
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;