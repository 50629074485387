import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import { CURRENT_VERSIONS } from '../legal-documents/versions';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const RegisterPage = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    acceptTerms: false,
    acceptPrivacy: false,
    termsLanguage: 'en',    // Default to English
    privacyLanguage: 'en'   // Default to English
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { signup, currentUser } = useAuth();

  // Redirect if already logged in
  React.useEffect(() => {
    if (currentUser) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const validateForm = () => {
    if (formData.password.length < 6) {
      setError('Password must be at least 6 characters long');
      return false;
    }
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return false;
    }
    if (!formData.acceptTerms || !formData.acceptPrivacy) {
      setError('Please accept the Terms of Service and Privacy Policy');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    try {
      setError('');
      setLoading(true);
      
      // Get current versions from the versions.js file
      const currentTermsVersion = CURRENT_VERSIONS.terms[formData.termsLanguage].version;
      const currentPrivacyVersion = CURRENT_VERSIONS.privacy[formData.privacyLanguage].version;

      // First create the account
      const userCredential = await signup(formData.email, formData.password);
      
      // Get the ID token
      const idToken = await userCredential.user.getIdToken();

      // Record the legal acceptance
      const response = await fetch(`${API_BASE_URL}/auth/legal-acceptance`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify({
          termsVersion: currentTermsVersion,
          privacyVersion: currentPrivacyVersion,
          termsLanguage: formData.termsLanguage,
          privacyLanguage: formData.privacyLanguage
        })
      });

      if (!response.ok) {
        throw new Error('Failed to record legal acceptance');
      }

      navigate('/');
    } catch (err) {
      console.error('Registration error:', err);
      setError(
        err.code === 'auth/email-already-in-use' ? 'An account with this email already exists' :
        err.code === 'auth/invalid-email' ? 'Invalid email address' :
        'Failed to create account. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="prediction-details">
      <h2>Create Account</h2>
      {error && <p className="error-message">{error}</p>}
      
      <form onSubmit={handleSubmit} className="login-form">
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="prediction-input"
            disabled={loading}
          />
        </div>

        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            className="prediction-input"
            disabled={loading}
          />
        </div>

        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            className="prediction-input"
            disabled={loading}
          />
        </div>

       <div className="consent-group">
          <label className="checkbox-label">
            <input
              type="checkbox"
              name="acceptTerms"
              checked={formData.acceptTerms}
              onChange={handleChange}
            />
            <span>
              I accept the <Link to="/legal/terms/en" target="_blank" className="consent-link">Terms of Service</Link>
            </span>
          </label>

          <label className="checkbox-label">
            <input
              type="checkbox"
              name="acceptPrivacy"
              checked={formData.acceptPrivacy}
              onChange={handleChange}
            />
            <span>
              I accept the <Link to="/legal/privacy/en" target="_blank" className="consent-link">Privacy Policy</Link>
            </span>
          </label>
        </div>

        <div className="form-actions">
          <button 
            type="submit" 
            className="create-prediction-btn"
            disabled={loading}
          >
            {loading ? 'Creating Account...' : 'Create Account'}
          </button>
          <p className="register-link">
            Already have an account?{' '}
            <button
              type="button"
              onClick={() => navigate('/login')}
              className="text-button"
              disabled={loading}
            >
              Login
            </button>
          </p>
        </div>
      </form>
    </div>
  );
};

export default RegisterPage;