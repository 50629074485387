import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ShareButton } from './ShareButton';

const ITEMS_PER_PAGE = 20;

const PredictionList = ({ predictions }) => {
  const [currentPage, setCurrentPage] = useState(1);
  
  const totalPages = Math.ceil(predictions.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentPredictions = predictions.slice(startIndex, endIndex);

  const handleShareClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="prediction-listed">
      {currentPredictions.map(prediction => {
        const currentChance = prediction.current_chance || prediction.currentChance;
        
        return (
          <Link 
            key={prediction.id}
            to={`/prediction/${prediction.id}`}
            className="prediction prediction-link"
          >
            <p className="small-text">
              {prediction.type === 'chance' 
                ? `I ${prediction.resolved ? 'predicted there was' : 'predict there\'s'} a` 
                : `I ${prediction.resolved ? 'predicted' : 'predict'}`}
            </p>
            
            <h3 className="main-prediction-text">
              {prediction.type === 'chance' ? (
                <>{currentChance}% chance of {prediction.title}</>
              ) : (
                prediction.title
              )}
            </h3>

            <div onClick={handleShareClick}>
              <ShareButton 
  title={prediction.title}
  shareId={prediction.shareId || prediction.share_id}
  username={prediction.username}
  chance={prediction.current_chance || prediction.currentChance}
  type={prediction.type}
  resolved={prediction.resolved}
  className="list-share-button"
/>
            </div>
          </Link>
        );
      })}

      {totalPages > 1 && (
        <div className="pagination">
          {currentPage > 1 && (
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              className="pagination-button"
            >
              Previous
            </button>
          )}
          
          <span className="page-info">
            Page {currentPage} of {totalPages}
          </span>
          
          {currentPage < totalPages && (
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              className="pagination-button"
            >
              Next
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default PredictionList;